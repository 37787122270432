import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import Layout from "components/shared/Layout";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/infographic.svg";
import InfographicsCards from "components/infographics/InfographicsCards";
import { graphql } from "gatsby";

export default ({ data }) => (
  <>
    <Meta url="/infographics/" />
    <Nav />
    <LargeTitle
      size="small"
      img={icon}
      green="Infographics"
      text="The latest statistics, facts and figures about the Chinese app and game market, presented in easy-to-read downloadable infographics and charts for your use."
    />
    <Layout>
      <InfographicsCards
        posts={data.allWpInfographic.edges}
        category="infographics"
      />
    </Layout>
    <CTA />
    <Footer />
  </>
);

export const pageQuery = graphql`
  query {
    allWpCategory {
      edges {
        node {
          id
          count
          name
          slug
        }
      }
    }
    allWpInfographic(sort: { fields: date, order: DESC }) {
      edges {
        node {
          content
          date
          id
          slug
          title
          featuredImage {
            node {
              link
              id
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              slug
              description
              name
            }
          }
        }
      }
    }
  }
`;
