import React from "react";
import * as classes from "./index.module.less";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { formatBlogDate } from "src/utils/functions";

let Item = ({ node, category }) => {
  const image = getImage(node.featuredImage.node.localFile);
  return (
    <Link to={`/${category}/${node.slug}/`} className={classes.item}>
      {image ? (
        <div className={classes.image}>
          <GatsbyImage
            image={image}
            alt={node.title}
            loading="lazy"
            width={320}
            height={210}
          />
        </div>
      ) : (
        <i></i>
      )}
      <span className={classes.text}>
        <h2>{node.title}</h2>
        <p className={classes.date}>{formatBlogDate(node.date)}</p>
      </span>
    </Link>
  );
};

export default ({ posts, category }) => (
  <div className={classes.container}>
    {posts.map((it, index) => (
      <Item key={index} {...it} category={category} />
    ))}
  </div>
);
